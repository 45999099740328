var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import TextField from '@mui/material/TextField';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
export const MainTextField = (props) => {
    const { labelText, error } = props, restProps = __rest(props, ["labelText", "error"]);
    const inputProps = {
        min: 0,
    };
    if (props.maxLength) {
        inputProps.maxLength = props.maxLength;
    }
    return (_jsxs(_Fragment, { children: [labelText && !props.label && (_jsx("div", { className: `mb-1 ${props.required && 'required'}`, children: labelText })), _jsx(TextField, Object.assign({ InputProps: {
                    endAdornment: ((props.required && !props.value) || error) && (_jsx(ErrorOutlineIcon, { className: 'text-danger' })),
                    inputProps: inputProps,
                }, variant: "outlined", size: "small", error: (props.required && !props.value) || error, fullWidth: true, onKeyDown: props.onEnter
                    ? (ev) => {
                        var _a;
                        if (ev.key === 'Enter') {
                            (_a = props.onEnter) === null || _a === void 0 ? void 0 : _a.call(props);
                            ev.preventDefault();
                        }
                    }
                    : undefined }, restProps))] }));
};
