var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import './index.css';
export const MainButton = (props) => {
    const { fullWidth, buttonType, loading, startIcon, disabled, className, cancel } = props, restProps = __rest(props, ["fullWidth", "buttonType", "loading", "startIcon", "disabled", "className", "cancel"]);
    const isContain = buttonType === 'contain';
    return (_jsx(Button, Object.assign({}, restProps, { className: className +
            (cancel
                ? isContain
                    ? ' cancelButton'
                    : ' cancelButtonOutlined'
                : isContain
                    ? ' primaryButton'
                    : ' primaryButtonOutlined'), style: fullWidth ? { width: '100%' } : {}, startIcon: loading ? (_jsx(CircularProgress, { className: 'loadingSend-icon' })) : startIcon ? (startIcon) : null, disabled: loading || disabled, children: props.children })));
};
