var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { MainButton } from '../MainButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import './index.css';
export const CreateButton = (props) => {
    const { className } = props, restProps = __rest(props, ["className"]);
    return (_jsx(MainButton, Object.assign({}, restProps, { className: className + ' create-button', buttonType: 'outline', startIcon: _jsx(AddCircleOutlineIcon, { className: 'create-button-icon' }), children: props.children })));
};
